import ChocolatePudding from "../images/home-scroll/ChocolatePudding.svg";
import FeaturedRecipe from "../images/home-scroll/FeaturedRecipe.svg";

const scrollList = [
	{
		name: "featured-recipe",
		image: FeaturedRecipe,
	},
	{
		name: "chocolate-pudding",
		image: ChocolatePudding,
	},
	{
		name: "featured-recipe",
		image: FeaturedRecipe,
	},
	{
		name: "chocolate-pudding",
		image: ChocolatePudding,
	},
	{
		name: "featured-recipe",
		image: FeaturedRecipe,
	},
	{
		name: "chocolate-pudding",
		image: ChocolatePudding,
	},
	{
		name: "featured-recipe",
		image: FeaturedRecipe,
	},
	{
		name: "chocolate-pudding",
		image: ChocolatePudding,
	},
	{
		name: "featured-recipe",
		image: FeaturedRecipe,
	},
	{
		name: "chocolate-pudding",
		image: ChocolatePudding,
	},
];

export default scrollList;
